var N = Object.defineProperty;
var B = (e, t, n) => t in e ? N(e, t, { enumerable: !0, configurable: !0, writable: !0, value: n }) : e[t] = n;
var S = (e, t, n) => B(e, typeof t != "symbol" ? t + "" : t, n);
import { errorService as J } from "@waitroom/error-service";
import { logger as d } from "@waitroom/logger";
import { LOGGER_SERVICE_BRAID as u } from "@waitroom/models";
import { parseJson as T, retryWithBackoff as L, parseStream as H } from "@waitroom/utils";
const F = (e) => e ? `?${Object.keys(e).map((t) => `${t}=${encodeURIComponent(e[t])}`).join("&")}` : "";
class G {
  constructor() {
    S(this, "handlerRef", { id: -1 });
  }
  get handler() {
    return this.handlerRef.id;
  }
  set handler(t) {
    this.handlerRef.id = t;
  }
  clear() {
    clearTimeout(this.handlerRef.id);
  }
}
function K(e, t, n = new G()) {
  let s = !1;
  const r = () => {
    n.handler = setTimeout(() => {
      e(() => {
        s = !0, n.clear();
      }), s || r();
    }, t);
  };
  return r(), n;
}
var q = window.fetch, z = window.AbortController, I = window.Headers;
async function X(e, t = {}) {
  if (t = { ...t }, t.headers ? t.headers = new I(t.headers) : t.headers = new I(), t.version && console.assert(
    Array.isArray(t.version),
    "fetch(): `version` must be an array"
  ), t.parents && console.assert(
    Array.isArray(t.parents),
    "fetch(): `parents` must be an array"
  ), t.version && t.headers.set(
    "version",
    t.version.map(JSON.stringify).join(", ")
  ), t.parents && t.headers.set(
    "parents",
    t.parents.map(JSON.stringify).join(", ")
  ), t.subscribe && t.headers.set("subscribe", "true"), t.peer && t.headers.set("peer", t.peer), t.headers.set("X-Keep-Alive", "true"), t.cache = "no-cache", t.patches)
    if (console.assert(!t.body, "Cannot send both patches and body"), console.assert(
      typeof t.patches == "object",
      "Patches must be object or array"
    ), Array.isArray(t.patches) || (t.patches = [t.patches]), t.patches.length === 1) {
      let o = t.patches[0];
      t.headers.set("Content-Range", `${o.unit} ${o.range}`), t.headers.set(
        "Content-Length",
        `${new TextEncoder().encode(o.content).length}`
      ), t.body = o.content;
    } else
      t.headers.set("Patches", t.patches.length), t.body = t.patches.map((o) => {
        var f = `content-length: ${new TextEncoder().encode(o.content).length}`, p = `content-range: ${o.unit} ${o.range}`;
        return `${f}\r
${p}\r
\r
${o.content}\r
`;
      }).join(`\r
`);
  var n = t.signal, s = new z();
  t.signal = s.signal, n && n.addEventListener("abort", () => s.abort());
  var r = await q(e, t);
  r.subscribe = h, r.subscription = { [Symbol.asyncIterator]: g };
  const c = r.headers.get("X-Keep-Alive");
  function h(o, f) {
    if (!r.ok) throw new Error("Request returned not ok status:", r.status);
    if (r.bodyUsed)
      throw new Error("This response's body has already been read", r);
    Q(
      r.body,
      // Each time something happens, we'll either get a new
      // version back, or an error.
      (p, b) => {
        if (!b)
          o(p);
        else {
          const v = s.signal.aborted;
          if (s.abort(), f) f(b, v);
          else throw "Unhandled network error in subscription";
        }
      },
      c
    );
  }
  function g() {
    var o = !1, f = [], p = null, b = null;
    return {
      async next() {
        if (f.length > 0) return { done: !1, value: f.shift() };
        var v = new Promise((i, E) => {
          p = i, b = E;
        });
        o || (o = !0, h(
          (i) => p(i),
          (i) => b(i)
        ));
        var A = await v;
        return p = (i) => f.push(i), b = (i) => {
          throw i;
        }, { done: !1, value: A };
      }
    };
  }
  return r;
}
async function Q(e, t, n) {
  const s = e.getReader(), r = V(t);
  let c;
  if (n) {
    const h = (parseInt(n) + 15) * 1e3;
    c = K(() => r.checkTimeout(h), 1e4), r.keepAlive = !0;
  }
  for (; ; ) {
    let h, g;
    try {
      const o = await s.read();
      h = o.done, g = o.value;
    } catch (o) {
      c && c.clear(), r.timedOut || t(null, o);
      return;
    }
    if (h) {
      console.debug("Connection closed."), c && c.clear(), r.timedOut || t(null, "Connection closed");
      return;
    }
    r.read(g);
  }
}
const V = (e) => ({
  // A parser keeps some parse state
  state: { input: [] },
  // And reports back new versions as soon as they are ready
  cb: e,
  // Timestamp of last input
  last: Date.now(),
  timedOut: !1,
  keepAlive: !1,
  // You give it new input information as soon as you get it, and it will
  // report back with new versions as soon as it finds them.
  read(t) {
    const n = t;
    if (this.last = Date.now(), !(this.keepAlive && n.length > 12 && n[0] === 88 && n[1] == 45 && n[2] === 75 && n[3] === 101 && n[4] === 101 && n[5] === 112 && n[6] === 45 && n[7] === 65 && n[8] === 108 && n[9] === 105 && n[10] === 118 && n[11] === 101))
      for (this.state.input.push(...t); this.state.input.length; ) {
        try {
          this.state = M(this.state);
        } catch (s) {
          this.cb(null, s);
          return;
        }
        if (this.state.result === "success")
          this.cb({
            version: this.state.version,
            parents: this.state.parents,
            body: this.state.body,
            patches: this.state.patches,
            // Output extra_headers if there are some
            extra_headers: j(this.state.headers)
          }), this.state = { input: this.state.input };
        else if (this.state.result === "error") {
          this.cb(null, this.state.message);
          return;
        }
        if (this.state.result == "waiting") break;
      }
  },
  checkTimeout(t) {
    const n = Date.now() - this.last;
    d.service(u, 5, `Interval diff: ${n}`), n > t && (d.service(u, 3, "Connection timed out"), this.timedOut || e(null, "Connection timed out"), this.timedOut = !0);
  }
});
function M(e) {
  if (!e.headers) {
    const t = U(e.input);
    if (t.result === "error") return t;
    if (t.result === "waiting")
      return e.result = "waiting", e;
    e.headers = t.headers, e.version = e.headers.version, e.parents = e.headers.parents, e.input = t.input;
  }
  return W(e);
}
function U(e) {
  const t = Y(e);
  if (!t) return { result: "waiting" };
  const n = t.header_string, s = n.length, r = {}, c = /(:?[\w-_]+):\s?(.*)\r?\n?/gy;
  let h, g = !1;
  for (; h = c.exec(n); )
    r[h[1].toLowerCase()] = h[2], c.lastIndex === s && (g = !0);
  return g ? ("version" in r && (r.version = JSON.parse("[" + r.version + "]")), "parents" in r && (r.parents = JSON.parse("[" + r.parents + "]")), "patches" in r && (r.patches = JSON.parse(r.patches)), e = t.remaining_bytes, { result: "success", headers: r, input: e }) : {
    result: "error",
    message: 'Parse error in headers: "' + JSON.stringify(n.substr(c.lastIndex)) + '"',
    headers_so_far: r,
    last_index: c.lastIndex,
    headers_length: s
  };
}
function D(e) {
  const t = e.match(/(\S+)( (.*))?/);
  return t && { unit: t[1], range: t[3] || "" };
}
function W(e) {
  const t = parseInt(e.headers["content-length"]);
  if (isNaN(t)) {
    if (e.headers.patches != null) {
      e.patches = e.patches || [];
      let n = e.patches[e.patches.length - 1];
      for (; !(e.patches.length === e.headers.patches && (e.patches.length === 0 || "content" in n)); ) {
        if ((!n || "content" in n) && (n = {}, e.patches.push(n)), !("headers" in n)) {
          const s = U(e.input);
          if (s.result === "error") return s;
          if (s.result === "waiting")
            return e.result = "waiting", e;
          n.headers = s.headers, e.input = s.input;
        }
        {
          if (!("content-length" in n.headers))
            return {
              result: "error",
              message: "no content-length in patch",
              patch: n,
              input: new TextDecoder("utf-8").decode(new Uint8Array(e.input))
            };
          if (!("content-range" in n.headers))
            return {
              result: "error",
              message: "no content-range in patch",
              patch: n,
              input: new TextDecoder("utf-8").decode(new Uint8Array(e.input))
            };
          const s = parseInt(n.headers["content-length"]);
          if (e.input.length < s)
            return e.result = "waiting", e;
          const r = D(n.headers["content-range"]);
          if (!r)
            return {
              result: "error",
              message: "cannot parse content-range in patch",
              patch: n,
              input: new TextDecoder("utf-8").decode(new Uint8Array(e.input))
            };
          n.unit = r.unit, n.range = r.range, n.content = new TextDecoder("utf-8").decode(
            new Uint8Array(e.input.slice(0, s))
          ), n.extra_headers = j(n.headers), delete n.headers, e.input = e.input.slice(s);
        }
      }
      return e.result = "success", e;
    }
  } else {
    if (t > e.input.length)
      return e.result = "waiting", e;
    if (e.result = "success", e.headers["content-range"]) {
      const n = D(e.headers["content-range"]);
      if (!n)
        return {
          result: "error",
          message: "cannot parse content-range",
          range: e.headers["content-range"]
        };
      e.patches = [
        {
          unit: n.unit,
          range: n.range,
          content: new TextDecoder("utf-8").decode(
            new Uint8Array(e.input.slice(0, t))
          )
          // Question: Perhaps we should include headers here, like we do for
          // the Patches: N headers below?
          // headers: state.headers
        }
      ];
    } else
      e.body = new TextDecoder("utf-8").decode(
        new Uint8Array(e.input.slice(0, t))
      );
    return e.input = e.input.slice(t), e;
  }
  return {
    result: "error",
    message: "cannot parse body without content-length or patches header"
  };
}
function j(e) {
  const t = Object.assign({}, e), n = [
    "version",
    "parents",
    "patches",
    "content-length",
    "content-range"
  ];
  for (let s = 0; s < n.length; s++)
    delete t[n[s]];
  if (Object.keys(t).length !== 0)
    return t;
}
function Y(e) {
  let t = 0;
  for (; e[t] === 13 || e[t] === 10; )
    t++;
  if (t === e.length)
    return null;
  let n = t, s = 0;
  for (; n < e.length; ) {
    if (e[n] === 10 && e[n + 1] === 10) {
      s = 2;
      break;
    }
    if (e[n] === 10 && e[n + 1] === 13 && e[n + 2] === 10) {
      s = 3;
      break;
    }
    if (e[n] === 13 && e[n + 1] === 10 && e[n + 2] === 10) {
      s = 3;
      break;
    }
    if (e[n] === 13 && e[n + 1] === 10 && e[n + 2] === 13 && e[n + 3] === 10) {
      s = 4;
      break;
    }
    n++;
  }
  if (n === e.length)
    return null;
  const r = e.slice(t, n), c = new TextDecoder("utf-8").decode(
    new Uint8Array(r)
  );
  return {
    remaining_bytes: e.slice(n + s),
    header_string: c
  };
}
const Z = X, ie = {
  _connections: {},
  config: { interceptors: {} },
  close: function(e) {
    const t = this._connections[e];
    t && (d.service(u, 3, "Closing connection", e), t.abort.abort(`Close called for ${e}`), delete this._connections[e]);
  },
  fetch: async function(e) {
    var R;
    const { onError: t } = this.config.interceptors, {
      key: n,
      method: s = "GET",
      shouldReconnect: r = !0,
      subscribe: c = !0,
      headers: h = {},
      onFetched: g,
      onPatch: o,
      onError: f,
      params: p,
      signal: b,
      skipRetryErrorCodes: v = [401, 404],
      ...A
    } = e, i = n || A.url || "";
    if (d.service(u, 5, "Fetch", i), this._connections[i]) {
      const a = `Connection already exists ${i}`;
      if (d.service(u, 3, a), !r) throw new Error(a);
      this.close(i);
    }
    const E = F(p), P = `${A.baseURL}${A.url}${E}`, x = new AbortController();
    if (b) {
      if (b.aborted) return;
      b.addEventListener("abort", () => {
        d.service(
          u,
          3,
          "Abort connection",
          i
        ), x.abort(`Abort called for ${i}`), delete this._connections[i];
      });
    }
    try {
      return await new Promise(async (a, $) => {
        await L(() => new Promise(async (l, O) => {
          d.service(
            u,
            5,
            "Opening connection",
            i
          );
          const k = Z(P, {
            method: s,
            headers: h,
            subscribe: c,
            signal: x.signal,
            ...A
          });
          this._connections[i] = {
            abort: x,
            connection: k
          };
          let y;
          try {
            y = await k;
          } catch (w) {
            return $(w);
          }
          if (y.status > 399)
            return $({
              // @ts-expect-error types are wrong
              code: y.code || y.status,
              data: y == null ? void 0 : y.data,
              success: !1,
              error: y
            });
          y.subscribe(
            (w) => {
              if (w.body) {
                const _ = T(
                  w.body
                );
                return d.service(
                  u,
                  5,
                  "Body",
                  i
                ), g && g(_), _ ? a(_) : $(w);
              }
              if (w.patches && o) {
                d.service(
                  u,
                  5,
                  "Patch",
                  i
                );
                const _ = w.patches.map(
                  (m) => ({
                    op: m.unit,
                    path: m.range,
                    value: m.content ? T(m.content) : void 0
                  })
                );
                o(_);
              }
            },
            (w, _) => {
              if (d.service(u, 1, "Braid error", w), _) return a(void 0);
              O(w);
            }
          );
        }), {
          // infinite retries
          retries: -1,
          // skip retry
          filterError: v ? (l) => typeof l != "object" || !(v.includes(l == null ? void 0 : l.status) || v.includes(l == null ? void 0 : l.code)) : void 0,
          onRetry: (l) => {
            d.service(u, 5, "Retry", i, {
              attempt: l
            });
          }
        });
      });
    } catch (a) {
      if (typeof a == "object" && a) {
        const C = (R = a.error) == null ? void 0 : R.body;
        if (C) {
          const l = await H(C);
          if (l) {
            const O = T(l);
            a.error = O;
          }
        }
      }
      if (d.service(u, 1, "Error", i, a), J.report(a), f && await f(e, a), x.abort(`Error abort ${i}`), !t) throw a;
      return t(e, a);
    }
  }
};
export {
  ie as braidService,
  F as buildQueryString,
  K as setIntervalWithTimeout
};
