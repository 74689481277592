import { braidService as l } from "@waitroom/braid";
import { braidService as _ } from "@waitroom/braid";
import { bearerValue as A, httpService as a } from "@waitroom/http-client";
import { httpService as te } from "@waitroom/http-client";
let n;
const P = (e) => {
  n = {
    ...n,
    ...e
  };
}, I = (e) => {
  P(e);
}, b = async (e) => typeof e == "function" ? await e() : await e, S = async () => {
  const e = await b(n.getAuthToken);
  return {
    Authorization: e ? A(e) : void 0
  };
}, B = async () => {
  const [e, t] = await Promise.allSettled([
    b(n.getFingerPrint),
    b(n.getClientDetails)
  ]);
  return {
    "X-Fingerprint": e.status === "fulfilled" ? e.value : "",
    "X-Client-Info": JSON.stringify(t.status === "fulfilled" ? t.value : {}),
    "Accept-Language": "en"
    // TODO: This should be updated to pick the app's language
  };
}, R = (e, t) => `${e || n.baseURL}/${t || n.version}`, C = async (e, t) => ({
  ...await B(),
  // add auth header if non provided
  ...e && !(t != null && t.Authorization) ? await S() : {},
  ...t
}), m = (e) => async (t, { version: i, baseURL: c, headers: p, ...$ } = {}) => ({
  url: t,
  baseURL: R(c, i),
  headers: await C(e, p),
  ...$
}), o = m(!1), s = m(!0), O = {
  getFeed: async (e, t, i) => l.fetch(
    await s("/ai-feed/get", {
      ...n.defaultBraidParams,
      ...i,
      params: {
        ...i == null ? void 0 : i.params,
        sessionID: e,
        sessionRecurrenceID: t
      }
    })
  ),
  event: async (e, t, i) => a.post(
    await s("/ai-feed/event", {
      ...n.defaultBraidParams,
      ...i,
      data: {
        ...i == null ? void 0 : i.data,
        sessionID: e,
        sessionRecurrenceID: t
      }
    })
  ),
  updateFeed: async (e, t) => a.patch(await s(`/ai-feed/${e}`, t)),
  getSummary: async (e, t, i) => a.get(
    await s(
      `/post-session-summaries/by-session-ids/${e}`,
      {
        ...i,
        params: {
          formats: "md",
          ...i == null ? void 0 : i.params,
          sessionRecurrenceID: t
        }
      }
    )
  ),
  internalCopyAIFeed: async (e) => a.post(await s("/ai-feed/copy", e))
}, q = "/auth", D = {
  verifyOTP: async (e) => a.post(await o("/auth/verify-otp", e)),
  verifyOAuthOTP: async (e) => a.post(await o("/oauth/verify-otp", e)),
  requestOTP: async (e) => a.post(await o("/auth/request-otp", e)),
  requestOAuthOTP: async (e) => a.post(await o("/oauth/request-otp", e)),
  verifyAuthTokenProvider: async (e) => a.post(await o("/auth/social-token", e)),
  verifyOAuthTokenProvider: async (e) => a.post(await o("/oauth/social-token", e)),
  verifyAuthCodeProvider: async (e) => a.post(await o("/auth/social-code", e)),
  verifyOAuthCodeProvider: async (e) => a.post(await o("/oauth/social-code", e)),
  refreshToken: async (e) => a.put(await o("/auth/refresh-auth-token", e)),
  logout: async (e) => a.post(await s("/auth/logout", e)),
  hostOptIn: async (e) => a.patch(
    await s("/auth/user/host-opt-in", e)
  ),
  user: async (e) => a.get(await s("/auth/user", e)),
  registerGuest: async (e) => a.post(
    await s("/auth/guest", e)
  )
}, L = {
  getCalndrLinkEvent: async (e) => a.post({
    url: "https://calndr.link/api/events",
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
      ...e.headers
    },
    ...e
  })
}, U = {
  getPresignedUrl: async (e) => a.get(
    await s("/image-uploads/presigned-url", e)
  )
}, d = "/integrations", u = `${d}/salesforce`, y = `${d}/hubspot`, F = {
  getProviders: async (e) => a.get(await o(`${d}/providers`, e)),
  getConnections: async (e) => a.get(
    await s(`${d}/connections`, e)
  ),
  getAction: async (e, t, i) => a.get(
    await s(`${d}/${e}/${t}`, i)
  ),
  callAction: async (e, t, i) => a.post(
    await s(`${d}/${e}/${t}`, i)
  ),
  getFromAbsoluteURL: async (e, t) => a.get(await s("", { url: e, ...t })),
  authorize: async (e) => a.get(
    await s(`${d}/authorize`, e)
  ),
  disconnect: async (e) => a.delete(
    await s(`${d}/disconnect`, e)
  ),
  getBindingsByOwnerSessionRecurrencePerProvider: async (e, t, i) => l.fetch(
    await s(
      `${d}/-/get-bindings-by-owner-session-recurrence-per-provider`,
      {
        ...i,
        ...n.defaultBraidParams,
        params: {
          sessionId: e,
          sessionRecurrenceId: t
        }
      }
    )
  ),
  // Salesforce
  salesforce: {
    createBinding: async (e) => a.post(await s(`${u}/bindings`, e)),
    deleteBinding: async (e, t) => a.delete(
      await s(
        `${u}/bindings/${e}`,
        t
      )
    ),
    getAccountById: async (e, t) => a.get(
      await s(
        `${u}/accounts/${e}`,
        t
      )
    ),
    getOpportunityById: async (e, t) => a.get(
      await s(
        `${u}/opportunities/${e}`,
        t
      )
    ),
    listBindableRecordsAsOptions: async (e) => a.get(
      await s(
        `${u}/-/list-bindable-records-as-options`,
        e
      )
    ),
    createContact: async (e, t) => a.post(
      await s(`${u}/contacts`, {
        data: e,
        ...t
      })
    ),
    getContacts: async (e, t, i) => a.get(
      await s(
        `${u}/contacts/-/list-related-to-record`,
        {
          params: {
            ...i == null ? void 0 : i.params,
            recordId: e,
            recordType: t
          },
          ...i
        }
      )
    ),
    getLeadById: async (e, t) => a.get(
      await s(
        `${u}/leads/${e}`,
        t
      )
    )
  },
  // Hubspot
  hubspot: {
    createBinding: async (e) => a.post(await s(`${y}/bindings`, e)),
    deleteBinding: async (e, t) => a.delete(
      await s(
        `${y}/bindings/${e}`,
        t
      )
    ),
    getCompanyById: async (e, t) => a.get(
      await s(
        `${y}/companies/${e}`,
        t
      )
    ),
    getDealById: async (e, t) => a.get(
      await s(`${y}/deals/${e}`, t)
    ),
    listBindableRecordsAsOptions: async (e) => a.get(
      await s(
        `${y}/-/list-bindable-records-as-options`,
        e
      )
    ),
    getContacts: async (e, t, i) => a.get(
      await s(
        `${y}/contacts/-/list-related-to-record`,
        {
          params: {
            ...i == null ? void 0 : i.params,
            recordId: e,
            recordType: t
          },
          ...i
        }
      )
    ),
    getContactById: async (e, t) => a.get(
      await s(
        `${y}/contacts/${e}`,
        t
      )
    )
  }
}, g = "/memory", E = {
  ai: async (e, t, i) => {
    const c = await s(`${g}/ai-stream`, t);
    return n.fetchEventSource(`${c.baseURL}${c.url}`, {
      method: "POST",
      body: JSON.stringify(c.data),
      headers: c.headers,
      ...i,
      onmessage: e
    });
  },
  thread: async (e, t) => a.get(
    await s(`${g}/threads/${e}`, t)
  ),
  threadMessages: async (e, t) => a.get(
    await s(
      `${g}/threads/${e}/messages`,
      t
    )
  ),
  threads: async (e) => a.get(
    await s(`${g}/threads`, e)
  ),
  deleteThread: async (e, t) => a.delete(
    await s(`${g}/threads/${e}`, t)
  ),
  stop: async (e) => a.post(
    await s(`${g}/stop`, e)
  ),
  feedback: async (e) => a.patch(
    await s(`${g}/feedback`, e)
  ),
  suggestions: async (e) => a.get(
    await s(`${g}/suggestions`, e)
  )
}, z = {
  getStatus: async (e, t, i) => a.get(
    await s(
      `/recordings/${e}/recurrence/${t}/status`,
      { ...i, version: "v2.0" }
    )
  ),
  getRecording: async (e, t, i) => a.get(
    await s(
      `/recordings/${e}/recurrence/${t}/recording`,
      { ...i, version: "v2.0" }
    )
  ),
  getPeople: async (e, t, i) => a.get(
    await s(
      `/recordings/${e}/recurrence/${t}/people`,
      { ...i, version: "v2.0" }
    )
  ),
  getEpisodes: async (e, t) => a.get(
    await s(`/recordings/${e}/episodes`, {
      ...t,
      version: "v2.0"
    })
  ),
  getSessionRecordings: async (e, t, i) => a.get(
    await s(
      `/recordings/by-session/${e}/${t}`,
      i
    )
  ),
  getLatestPlayable: async (e) => a.get(
    await s("/recordings/latest-playable", e)
  )
}, M = {
  getById: async (e, t) => l.fetch(
    await s(`/sessions/get/${e}`, {
      ...n.defaultBraidParams,
      ...t
    })
  ),
  getFeatured: async (e) => a.get(await o("/sessions/featured", e)),
  subscribe: async (e) => a.post(
    await s("/sessions/subscribe", e)
  ),
  unsubscribe: async (e) => a.post(
    await s("/sessions/unsubscribe", e)
  ),
  getCategories: async (e) => a.get(await s("/sessions/category", e)),
  create: async (e) => a.post(await s("/sessions", e)),
  update: async (e, t, i) => a.patch(
    await s(
      `/sessions/${e}/recurrence/${t}`,
      i
    )
  ),
  end: async (e) => a.post(await s(`/meeting/${e}/end`)),
  delete: async (e, t, i) => a.patch(
    await s(
      `sessions/${e}/recurrence/${t}`,
      i
    )
  ),
  getPast: async (e) => a.get(
    await s("/sessions/get-past-sessions", e)
  ),
  getFuture: async (e) => a.get(
    await s("/sessions/get-future-sessions", e)
  ),
  restartSession: async (e, t, i) => a.patch(
    await s(
      `/sessions/${e}/recurrence/${t}/recur`,
      i
    )
  ),
  getSessionsLibraryHistory: async (e) => a.get(
    await s("/sessions/library/history", e)
  ),
  getSessionsLibraryUpcoming: async (e) => a.get(
    await s("/sessions/library/upcoming", e)
  ),
  presence: async (e, t) => l.fetch(
    await s(`/sessions/presence/${e}`, {
      ...n.defaultBraidParams,
      ...t
    })
  ),
  updateParticipantMetadata: async (e, t) => a.patch(
    await s(`meeting/${e}/participant`, t)
  ),
  registerGuest: async (e) => a.post(
    await s("/sessions/guest", e)
  )
}, r = "/sessions/access", V = {
  inReview: async (e) => l.fetch(
    await s(`${r}/in-review`, {
      ...n.defaultBraidParams,
      ...e
    })
  ),
  userSessionRequest: async (e) => l.fetch(
    await s(`${r}/get`, {
      ...n.defaultBraidParams,
      ...e
    })
  ),
  getViewer: async (e) => a.get(await s(`${r}/viewer`, e)),
  getViewerAccessStats: async (e) => a.get(
    await s(`${r}/get-viewer-access-stats`, e)
  ),
  getInReviewViewerAccessStats: async (e) => a.post(
    await s(
      `${r}/get-in-review-access-requests-count`,
      e
    )
  ),
  requestAccess: async (e) => a.post(
    await s(`${r}/request-access`, e)
  ),
  update: async (e) => a.put(await s(`${r}/request`, e)),
  add: async (e) => a.put(await s(`${r}/add`, e)),
  remove: async (e) => a.delete(await s(`${r}/remove`, e))
}, f = "/session-recordings/off-the-record", x = {
  enableOffTheRecord: async (e) => a.post(
    await s(`${f}/enable`, {
      version: "v2.0",
      ...e
    })
  ),
  disableOffTheRecord: async (e) => a.post(
    await s(`${f}/disable`, {
      version: "v2.0",
      ...e
    })
  )
}, j = {
  getPlans: async (e) => a.get(await o("/billing/plans", { ...e })),
  getPlan: async (e, t) => a.get(
    await o(`/billing/plans/${e}`, {
      ...t
    })
  )
}, G = {
  getByUserId: async (e, t, i, c = "audience") => a.post(
    await s("/agora/token", {
      data: {
        userId: t,
        sessionId: e,
        role: c,
        expirationTs: i,
        tokenType: "userAccount"
      }
    })
  ),
  getByUid: async (e, t, i, c = "audience") => a.post(
    await s("/agora/token", {
      data: {
        sessionId: e,
        uid: t,
        role: c,
        expirationTs: i,
        tokenType: "uid"
      }
    })
  ),
  getChat: async (e, t, i) => a.get(
    await s("/chat/token", {
      ...i,
      params: {
        ...i == null ? void 0 : i.params,
        sessionID: e,
        sessionRecurrenceID: t
      }
    })
  ),
  getStream: async (e, t) => a.post(
    await s(`/meeting/${e}/token`, t)
  )
}, J = {
  getById: async (e, t) => a.get(await s(`/users/id/${e}`, t)),
  getCurrent: async (e) => a.get(await s("/users/me", e)),
  update: async (e, { data: t, ...i }) => a.put(
    await s(`/users/id/${e}`, {
      ...i,
      data: {
        userData: t
      }
    })
  ),
  getSubscriptionPlan: async (e, t) => a.get(
    await s(`/users/${e}/plan`, {
      ...t
    })
  ),
  updateSubscriptionPlan: async (e, t) => a.patch(
    await s(`/users/${e}/plan`, {
      ...t
    })
  ),
  reactivateSubscriptionPlan: async (e, t) => a.post(
    await s(`/users/${e}/plan/reactivate`, {
      ...t
    })
  ),
  cancelSubscriptionPlan: async (e, t) => a.post(
    await s(`/users/${e}/plan/cancel`, {
      ...t
    })
  ),
  updatePaymentTransaction: async (e, t) => a.get(
    await s(
      `/users/${e}/update-payment-method-transaction`,
      {
        ...t
      }
    )
  ),
  activateTrial: async (e, t) => a.post(
    await s(`/users/${e}/activate-trial`, {
      ...t
    })
  ),
  createCustomer: async (e, t) => a.post(
    await s(`/users/${e}/customer`, {
      ...t
    })
  ),
  getTransactions: async (e, t) => a.get(
    await s(`/users/${e}/transactions`, {
      ...t
    })
  ),
  getTransactionInvoice: async (e, t, i) => a.get(
    await s(
      `/users/${t}/transactions/${e}/invoice`,
      { ...i }
    )
  ),
  getPaymentDetails: async (e, t) => a.get(
    await s(`/users/${e}/payment-method-details`, {
      ...t
    })
  ),
  requestDelete: async () => a.delete(await s("/users/me"))
}, N = {
  get: async (e) => a.get(await s("/meeting-types", e))
}, X = (e) => e == null ? void 0 : e.data, W = (e) => {
  var t;
  return (t = e == null ? void 0 : e.data) == null ? void 0 : t.data;
}, H = (e) => {
  var p, $;
  if (!e) return;
  let t, i, c = !1;
  if ("headers" in e && "error" in e) {
    const {
      errCode: h,
      statusCode: w,
      message: v
    } = e.error || {};
    t = h || w || e.code || ((p = e.data) == null ? void 0 : p.code), i = (($ = e.data) == null ? void 0 : $.message) || e.message || v;
  } else {
    let {
      code: h,
      message: w,
      success: v
    } = "data" in e ? (e == null ? void 0 : e.data) || {} : e || {};
    "message" in e && w === void 0 && ({ code: h, message: w, success: v } = e), t = h, i = w, c = !!v;
  }
  return {
    code: t,
    message: i,
    success: c
  };
}, K = (e) => ({
  success: !0,
  code: 200,
  data: e
}), Q = (e) => ({
  success: !0,
  code: 200,
  data: {
    success: !0,
    code: 200,
    data: e
  }
});
export {
  O as aiApiService,
  D as authApiService,
  q as authBaseEndpoint,
  _ as braidService,
  o as buildApiParams,
  s as buildApiParamsWithAuth,
  C as buildHeaders,
  Q as buildSuccessApiRequestResponse,
  K as buildSuccessRequestResponse,
  L as calendarApiService,
  n as config,
  X as getApiData,
  W as getApiResponseData,
  S as getAuthHeader,
  R as getBaseURL,
  H as getResponse,
  te as httpService,
  U as imageApiService,
  I as initCommonApi,
  F as integrationsApiService,
  E as meetingMemoryApiService,
  z as recordingApiService,
  V as sessionAccessApiService,
  M as sessionApiService,
  x as sessionRecordingsApiService,
  j as subscriptionPlanApiService,
  G as tokenApiService,
  P as updateConfig,
  J as userApiService,
  N as userMeetingTypesService
};
