import { authApiService as d } from "@waitroom/common-api";
import { logger as g } from "@waitroom/logger";
import { STORAGE_AUTH_TOKENS as a, LOGGER_SERVICE_AUTH as T } from "@waitroom/models";
import { tryCatchAsync as m } from "@waitroom/utils";
import { jwtDecode as v } from "jwt-decode";
const l = (e) => e.reduce(
  (t, r) => ({
    ...t,
    [r]: !0
  }),
  {}
), h = {
  userId: void 0,
  isAuthenticated: !1,
  status: "initialized",
  isGuest: !1
}, y = {
  _refreshPromise: void 0,
  _cache: {},
  config: {},
  init: function(e) {
    this.config = e;
  },
  getAnonymous: () => h,
  getDecodedToken: function(e) {
    if (this._cache[e]) return this._cache[e];
    const t = v(e);
    if (t)
      return this._cache[e] = t, t;
  },
  setup: async function(e, t = !0) {
    const r = e || this.getStorageTokens();
    if (!(r != null && r.accessToken)) return h;
    const s = this.decode(r, 600);
    s != null && s.accessToken && this.saveStorageTokens({
      accessToken: s.accessToken,
      refreshToken: s.refreshToken
    });
    const o = s || (t ? await this.refresh(r.refreshToken) : void 0);
    return o != null && o.accessToken ? o : this.logout();
  },
  getStorageTokens: function() {
    var e;
    return (e = this.config.storage) == null ? void 0 : e.getParsed(
      a
    );
  },
  saveStorageTokens: function(e) {
    var t;
    (t = this.config.storage) == null || t.set(a, e);
  },
  clearStorageTokens: function() {
    var e;
    (e = this.config.storage) == null || e.remove(a);
  },
  isTokenValid: function(e, t = 0) {
    const r = e ? this.getDecodedToken(e) : void 0;
    return !!r && !this.hasExpired(r.exp, t);
  },
  _refreshFn: async function(e) {
    var i;
    if (!e) return;
    const t = await m(
      () => d.refreshToken({
        data: {
          refreshToken: e
        }
      })
    )();
    if ((t == null ? void 0 : t.code) === 401) return;
    const { authToken: r, refreshToken: s } = ((i = t == null ? void 0 : t.data) == null ? void 0 : i.data) || {};
    if (!r) return;
    const o = { accessToken: r, refreshToken: s }, n = this.decode(o);
    if (n)
      return this.saveStorageTokens(o), n;
  },
  refresh: async function(e) {
    var o;
    if (g.service(T, 1, "Refreshing auth token", e), this._refreshPromise) return await this._refreshPromise;
    const t = (o = this.getStorageTokens()) == null ? void 0 : o.refreshToken, r = e || t;
    if (!r) return;
    this._refreshPromise = this._refreshFn(r);
    const s = await this._refreshPromise || await this._refreshFn(t);
    return g.service(
      T,
      1,
      "Refreshed auth token",
      s == null ? void 0 : s.refreshToken
    ), this._refreshPromise = void 0, s;
  },
  /** Decode token with expiration tolerance in seconds */
  decode: function(e, t) {
    const {
      userID: r,
      guestSurrogateID: s,
      guestEmail: o,
      guestFullName: n,
      exp: i,
      roleIDs: k = []
    } = this.getDecodedToken(e.accessToken) || {}, c = r || (s ? String(s) : void 0);
    if (!c || this.hasExpired(i, t)) return;
    const u = !r, f = {
      ...e,
      userId: c,
      isAuthenticated: !0,
      expires: i,
      agentId: c,
      isGuest: u,
      roles: l(k),
      status: "initialized"
    };
    return u && (f.currentUser = {
      id: String(s),
      firstName: n || "",
      email: o || ""
    }), f;
  },
  logout: async function() {
    return await d.logout({ throwError: !1 }), this.clearStorageTokens(), h;
  },
  /** Check if token has expired with expiration tolerance in seconds */
  hasExpired: (e, t = 0) => !e || Date.now() + t * 1e3 > e * 1e3
};
export {
  y as authService
};
