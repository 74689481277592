import { SessionAccessRuleStatus as V } from "@waitroom/models";
var fe = typeof globalThis < "u" ? globalThis : typeof window < "u" ? window : typeof global < "u" ? global : typeof self < "u" ? self : {}, de = { exports: {} };
(function(t, e) {
  (function(r, s) {
    t.exports = s();
  })(fe, function() {
    var r = 1e3, s = 6e4, i = 36e5, S = "millisecond", E = "second", g = "minute", T = "hour", D = "day", F = "week", R = "month", b = "quarter", v = "year", N = "date", se = "Invalid Date", ue = /^(\d{4})[-/]?(\d{1,2})?[-/]?(\d{0,2})[Tt\s]*(\d{1,2})?:?(\d{1,2})?:?(\d{1,2})?[.:]?(\d+)?$/, ae = /\[([^\]]+)]|Y{1,4}|M{1,4}|D{1,2}|d{1,4}|H{1,2}|h{1,2}|a|A|m{1,2}|s{1,2}|Z{1,2}|SSS/g, Ee = { name: "en", weekdays: "Sunday_Monday_Tuesday_Wednesday_Thursday_Friday_Saturday".split("_"), months: "January_February_March_April_May_June_July_August_September_October_November_December".split("_"), ordinal: function(_) {
      var u = ["th", "st", "nd", "rd"], n = _ % 100;
      return "[" + _ + (u[(n - 20) % 10] || u[n] || u[0]) + "]";
    } }, j = function(_, u, n) {
      var a = String(_);
      return !a || a.length >= u ? _ : "" + Array(u + 1 - a.length).join(n) + _;
    }, _e = { s: j, z: function(_) {
      var u = -_.utcOffset(), n = Math.abs(u), a = Math.floor(n / 60), o = n % 60;
      return (u <= 0 ? "+" : "-") + j(a, 2, "0") + ":" + j(o, 2, "0");
    }, m: function _(u, n) {
      if (u.date() < n.date()) return -_(n, u);
      var a = 12 * (n.year() - u.year()) + (n.month() - u.month()), o = u.clone().add(a, R), f = n - o < 0, d = u.clone().add(a + (f ? -1 : 1), R);
      return +(-(a + (n - o) / (f ? o - d : d - o)) || 0);
    }, a: function(_) {
      return _ < 0 ? Math.ceil(_) || 0 : Math.floor(_);
    }, p: function(_) {
      return { M: R, y: v, w: F, d: D, D: N, h: T, m: g, s: E, ms: S, Q: b }[_] || String(_ || "").toLowerCase().replace(/s$/, "");
    }, u: function(_) {
      return _ === void 0;
    } }, w = "en", K = {};
    K[w] = Ee;
    var ne = "$isDayjsObject", J = function(_) {
      return _ instanceof x || !(!_ || !_[ne]);
    }, q = function _(u, n, a) {
      var o;
      if (!u) return w;
      if (typeof u == "string") {
        var f = u.toLowerCase();
        K[f] && (o = f), n && (K[f] = n, o = f);
        var d = u.split("-");
        if (!o && d.length > 1) return _(d[0]);
      } else {
        var m = u.name;
        K[m] = u, o = m;
      }
      return !a && o && (w = o), o || !a && w;
    }, A = function(_, u) {
      if (J(_)) return _.clone();
      var n = typeof u == "object" ? u : {};
      return n.date = _, n.args = arguments, new x(n);
    }, l = _e;
    l.l = q, l.i = J, l.w = function(_, u) {
      return A(_, { locale: u.$L, utc: u.$u, x: u.$x, $offset: u.$offset });
    };
    var x = function() {
      function _(n) {
        this.$L = q(n.locale, null, !0), this.parse(n), this.$x = this.$x || n.x || {}, this[ne] = !0;
      }
      var u = _.prototype;
      return u.parse = function(n) {
        this.$d = function(a) {
          var o = a.date, f = a.utc;
          if (o === null) return /* @__PURE__ */ new Date(NaN);
          if (l.u(o)) return /* @__PURE__ */ new Date();
          if (o instanceof Date) return new Date(o);
          if (typeof o == "string" && !/Z$/i.test(o)) {
            var d = o.match(ue);
            if (d) {
              var m = d[2] - 1 || 0, p = (d[7] || "0").substring(0, 3);
              return f ? new Date(Date.UTC(d[1], m, d[3] || 1, d[4] || 0, d[5] || 0, d[6] || 0, p)) : new Date(d[1], m, d[3] || 1, d[4] || 0, d[5] || 0, d[6] || 0, p);
            }
          }
          return new Date(o);
        }(n), this.init();
      }, u.init = function() {
        var n = this.$d;
        this.$y = n.getFullYear(), this.$M = n.getMonth(), this.$D = n.getDate(), this.$W = n.getDay(), this.$H = n.getHours(), this.$m = n.getMinutes(), this.$s = n.getSeconds(), this.$ms = n.getMilliseconds();
      }, u.$utils = function() {
        return l;
      }, u.isValid = function() {
        return this.$d.toString() !== se;
      }, u.isSame = function(n, a) {
        var o = A(n);
        return this.startOf(a) <= o && o <= this.endOf(a);
      }, u.isAfter = function(n, a) {
        return A(n) < this.startOf(a);
      }, u.isBefore = function(n, a) {
        return this.endOf(a) < A(n);
      }, u.$g = function(n, a, o) {
        return l.u(n) ? this[a] : this.set(o, n);
      }, u.unix = function() {
        return Math.floor(this.valueOf() / 1e3);
      }, u.valueOf = function() {
        return this.$d.getTime();
      }, u.startOf = function(n, a) {
        var o = this, f = !!l.u(a) || a, d = l.p(n), m = function(P, M) {
          var H = l.w(o.$u ? Date.UTC(o.$y, M, P) : new Date(o.$y, M, P), o);
          return f ? H : H.endOf(D);
        }, p = function(P, M) {
          return l.w(o.toDate()[P].apply(o.toDate("s"), (f ? [0, 0, 0, 0] : [23, 59, 59, 999]).slice(M)), o);
        }, c = this.$W, O = this.$M, Y = this.$D, G = "set" + (this.$u ? "UTC" : "");
        switch (d) {
          case v:
            return f ? m(1, 0) : m(31, 11);
          case R:
            return f ? m(1, O) : m(0, O + 1);
          case F:
            var h = this.$locale().weekStart || 0, B = (c < h ? c + 7 : c) - h;
            return m(f ? Y - B : Y + (6 - B), O);
          case D:
          case N:
            return p(G + "Hours", 0);
          case T:
            return p(G + "Minutes", 1);
          case g:
            return p(G + "Seconds", 2);
          case E:
            return p(G + "Milliseconds", 3);
          default:
            return this.clone();
        }
      }, u.endOf = function(n) {
        return this.startOf(n, !1);
      }, u.$set = function(n, a) {
        var o, f = l.p(n), d = "set" + (this.$u ? "UTC" : ""), m = (o = {}, o[D] = d + "Date", o[N] = d + "Date", o[R] = d + "Month", o[v] = d + "FullYear", o[T] = d + "Hours", o[g] = d + "Minutes", o[E] = d + "Seconds", o[S] = d + "Milliseconds", o)[f], p = f === D ? this.$D + (a - this.$W) : a;
        if (f === R || f === v) {
          var c = this.clone().set(N, 1);
          c.$d[m](p), c.init(), this.$d = c.set(N, Math.min(this.$D, c.daysInMonth())).$d;
        } else m && this.$d[m](p);
        return this.init(), this;
      }, u.set = function(n, a) {
        return this.clone().$set(n, a);
      }, u.get = function(n) {
        return this[l.p(n)]();
      }, u.add = function(n, a) {
        var o, f = this;
        n = Number(n);
        var d = l.p(a), m = function(O) {
          var Y = A(f);
          return l.w(Y.date(Y.date() + Math.round(O * n)), f);
        };
        if (d === R) return this.set(R, this.$M + n);
        if (d === v) return this.set(v, this.$y + n);
        if (d === D) return m(1);
        if (d === F) return m(7);
        var p = (o = {}, o[g] = s, o[T] = i, o[E] = r, o)[d] || 1, c = this.$d.getTime() + n * p;
        return l.w(c, this);
      }, u.subtract = function(n, a) {
        return this.add(-1 * n, a);
      }, u.format = function(n) {
        var a = this, o = this.$locale();
        if (!this.isValid()) return o.invalidDate || se;
        var f = n || "YYYY-MM-DDTHH:mm:ssZ", d = l.z(this), m = this.$H, p = this.$m, c = this.$M, O = o.weekdays, Y = o.months, G = o.meridiem, h = function(M, H, L, Q) {
          return M && (M[H] || M(a, f)) || L[H].slice(0, Q);
        }, B = function(M) {
          return l.s(m % 12 || 12, M, "0");
        }, P = G || function(M, H, L) {
          var Q = M < 12 ? "AM" : "PM";
          return L ? Q.toLowerCase() : Q;
        };
        return f.replace(ae, function(M, H) {
          return H || function(L) {
            switch (L) {
              case "YY":
                return String(a.$y).slice(-2);
              case "YYYY":
                return l.s(a.$y, 4, "0");
              case "M":
                return c + 1;
              case "MM":
                return l.s(c + 1, 2, "0");
              case "MMM":
                return h(o.monthsShort, c, Y, 3);
              case "MMMM":
                return h(Y, c);
              case "D":
                return a.$D;
              case "DD":
                return l.s(a.$D, 2, "0");
              case "d":
                return String(a.$W);
              case "dd":
                return h(o.weekdaysMin, a.$W, O, 2);
              case "ddd":
                return h(o.weekdaysShort, a.$W, O, 3);
              case "dddd":
                return O[a.$W];
              case "H":
                return String(m);
              case "HH":
                return l.s(m, 2, "0");
              case "h":
                return B(1);
              case "hh":
                return B(2);
              case "a":
                return P(m, p, !0);
              case "A":
                return P(m, p, !1);
              case "m":
                return String(p);
              case "mm":
                return l.s(p, 2, "0");
              case "s":
                return String(a.$s);
              case "ss":
                return l.s(a.$s, 2, "0");
              case "SSS":
                return l.s(a.$ms, 3, "0");
              case "Z":
                return d;
            }
            return null;
          }(M) || d.replace(":", "");
        });
      }, u.utcOffset = function() {
        return 15 * -Math.round(this.$d.getTimezoneOffset() / 15);
      }, u.diff = function(n, a, o) {
        var f, d = this, m = l.p(a), p = A(n), c = (p.utcOffset() - this.utcOffset()) * s, O = this - p, Y = function() {
          return l.m(d, p);
        };
        switch (m) {
          case v:
            f = Y() / 12;
            break;
          case R:
            f = Y();
            break;
          case b:
            f = Y() / 3;
            break;
          case F:
            f = (O - c) / 6048e5;
            break;
          case D:
            f = (O - c) / 864e5;
            break;
          case T:
            f = O / i;
            break;
          case g:
            f = O / s;
            break;
          case E:
            f = O / r;
            break;
          default:
            f = O;
        }
        return o ? f : l.a(f);
      }, u.daysInMonth = function() {
        return this.endOf(R).$D;
      }, u.$locale = function() {
        return K[this.$L];
      }, u.locale = function(n, a) {
        if (!n) return this.$L;
        var o = this.clone(), f = q(n, a, !0);
        return f && (o.$L = f), o;
      }, u.clone = function() {
        return l.w(this.$d, this);
      }, u.toDate = function() {
        return new Date(this.valueOf());
      }, u.toJSON = function() {
        return this.isValid() ? this.toISOString() : null;
      }, u.toISOString = function() {
        return this.$d.toISOString();
      }, u.toString = function() {
        return this.$d.toUTCString();
      }, _;
    }(), re = x.prototype;
    return A.prototype = re, [["$ms", S], ["$s", E], ["$m", g], ["$H", T], ["$W", D], ["$M", R], ["$y", v], ["$D", N]].forEach(function(_) {
      re[_[1]] = function(u) {
        return this.$g(u, _[0], _[1]);
      };
    }), A.extend = function(_, u) {
      return _.$i || (_(u, x, A), _.$i = !0), A;
    }, A.locale = q, A.isDayjs = J, A.unix = function(_) {
      return A(1e3 * _);
    }, A.en = K[w], A.Ls = K, A.p = {}, A;
  });
})(de);
const oe = (t) => typeof t == "function", k = ({
  client: t,
  key: e,
  fn: r,
  skipEmpty: s
}) => {
  const i = t.getQueryData(e);
  s && i === void 0 || t.setQueryData(e, r(i));
}, W = (t) => k({
  ...t,
  fn: (e) => ({
    code: 200,
    success: !0,
    ...e,
    data: oe(t.dataOrFn) ? t.dataOrFn(e == null ? void 0 : e.data) : t.dataOrFn
  })
}), Se = {
  update: W,
  addToArray: (t) => W({
    ...t,
    dataOrFn: (e) => [
      ...Array.isArray(t.data) ? t.data : [t.data],
      ...e || []
    ]
  }),
  updateInArray: ({
    data: t,
    keyId: e,
    ...r
  }) => W({
    ...r,
    dataOrFn: (s) => s == null ? void 0 : s.map((i) => i[e] === t[e] ? t : i)
  }),
  removeFromArray: ({
    id: t,
    keyId: e,
    ...r
  }) => W({
    ...r,
    dataOrFn: (s) => s == null ? void 0 : s.filter((i) => i[e] !== t)
  }),
  addToPages: (t) => k({
    ...t,
    fn: (e) => {
      var s, i, S;
      const r = {
        pageParams: [...(e == null ? void 0 : e.pageParams) || []],
        pages: [...(e == null ? void 0 : e.pages) || []]
      };
      return r.pages[0] = {
        ...r.pages[0],
        data: {
          code: 200,
          success: !0,
          ...(s = r.pages[0]) == null ? void 0 : s.data,
          data: t.updateFn((S = (i = r.pages[0]) == null ? void 0 : i.data) == null ? void 0 : S.data)
        }
      }, r;
    }
  }),
  updateInPages: (t) => k({
    ...t,
    fn: (e) => {
      var s, i;
      if (!e || !((s = e.pages) != null && s.length)) return e;
      const r = {
        pageParams: [...e.pageParams],
        pages: [...e.pages]
      };
      for (let S = 0; S < r.pages.length; S++) {
        const E = (i = r.pages[S]) == null ? void 0 : i.data, g = E == null ? void 0 : E.data;
        if (!g) continue;
        const T = t.updateFn(g);
        if (E !== T && (r.pages[S] = {
          ...r.pages[S],
          data: {
            ...E,
            data: T
          }
        }, t.stopOnChange))
          break;
      }
      return r;
    }
  })
}, le = (t) => k({
  ...t,
  fn: (e) => {
    var r;
    return {
      code: 200,
      success: !0,
      ...e,
      data: {
        code: 200,
        success: !0,
        ...e == null ? void 0 : e.data,
        data: oe(t.dataOrFn) ? t.dataOrFn((r = e == null ? void 0 : e.data) == null ? void 0 : r.data) : t.dataOrFn
      }
    };
  }
}), Ce = {
  update: le
}, $ = {
  update: k,
  request: Se,
  apiRequest: Ce
}, Ye = "calendarEvent", Ne = "cmsPage", He = "cmsPages", Ke = "currentUser", he = "dashboardPastSessions", Z = "dashboardSessions", Pe = "featuredSessions", Fe = "integrationsConnections", Ge = "integrationsDirectURL", be = "integrationsGetAction", we = "integrationsProviders", me = "meetingMemoryMessages", Be = "meetingMemorySuggestions", ge = "meetingMemoryThread", pe = "meetingMemoryThreads", Le = "paymentDetails", Ue = "recording", ke = "recordingStatus", Ae = "session", qe = "sessionAccessRule", ie = "sessionAccessRules", ce = "sessionAiFeed", xe = "sessionEndSummary", Qe = "sessionEpisodes", We = "sessionPendingRulesStats", Oe = "sessionPresence", ee = "sessionRequestAccess", Ve = "sessionViewers", je = "streamToken", Je = "subscriptionPlans", Ze = "subscriptionReceipts", ye = "user", ze = "integrationsGetByOwnerSessionRecurrence", Xe = "userMeetingTypes", et = "salesforceProvider", tt = "salesforceGetBindingById", st = "salesforceGetAccountById", nt = "salesforceGetOpportunityById", rt = "salesforceListBindableRecordsAsOptions", it = "salesforceGetContacts", ot = "salesforceGetLeadById", ut = "hubspotProvider", at = "hubspotGetBindingById", Et = "hubspotGetCompanyById", _t = "hubspotGetDealById", ft = "hubspotListBindableRecordsAsOptions", dt = "hubspotGetContacts", St = "hubspotGetContactById", z = (t, e) => ["private", ...t, e], X = (t, e = "") => [
  ce,
  t,
  e
], lt = (t, e = "") => [
  ee,
  t,
  e,
  V.IN_REVIEW
], Ct = (t, e, r) => [ee, t, e, r || ""], mt = (t, e, r) => [Ae, t, e, r], gt = (t, e = "") => [
  Oe,
  t,
  e
], pt = (t) => [ye, t], Ie = (t) => [
  pe,
  t
], At = (t) => [
  ge,
  t
], U = (t) => [
  me,
  t
], Te = {
  add: ({
    client: t,
    data: e,
    sessionId: r,
    recurrenceId: s
  }) => {
    e && $.request.addToArray({
      client: t,
      key: X(r, s),
      data: [e]
    });
  },
  update: ({
    client: t,
    data: e,
    sessionId: r,
    recurrenceId: s
  }) => {
    e && $.request.update({
      client: t,
      key: X(r, s),
      dataOrFn: (i) => i ? (i.messages = i.messages.map(
        (S) => S.id === e.id ? e : S
      ), i) : { messages: [e], totalSummaryCount: 1 }
    });
  },
  remove: ({
    client: t,
    sessionId: e,
    recurrenceId: r,
    id: s
  }) => {
    $.request.removeFromArray({
      client: t,
      key: X(e, r),
      id: s,
      keyId: "id"
    });
  }
}, ct = {
  ...Te
}, Re = {
  addMessage: ({ client: t, data: e, id: r }) => {
    e && $.apiRequest.update(
      {
        client: t,
        key: U(r),
        dataOrFn: (s) => ({
          hasMore: !1,
          ...s,
          messages: [...(s == null ? void 0 : s.messages) || [], e],
          total: ((s == null ? void 0 : s.total) || 0) + 1
        })
      }
    );
  },
  updateMessage: ({ client: t, data: e, id: r }) => {
    e && $.apiRequest.update(
      {
        client: t,
        key: U(r),
        dataOrFn: (s) => s != null && s.messages ? {
          ...s,
          messages: s.messages.map(
            (i) => i.id === e.id ? { ...i, ...e } : i
          )
        } : s
      }
    );
  },
  updateLastAiMessage: ({
    client: t,
    data: e,
    id: r
  }) => {
    e && $.apiRequest.update(
      {
        client: t,
        key: U(r),
        dataOrFn: (s) => {
          var T;
          const i = s && {
            ...s,
            messages: s.messages ? [...s.messages] : void 0
          };
          if (!(i != null && i.messages)) return s;
          const S = i.messages.findLastIndex(
            (D) => (!e.id || D.id === e.id) && D.role === "ai"
          );
          if (S === -1) return s;
          const E = i.messages[S], g = { ...E, ...e, id: e.id || E.id };
          return e.content && (g.content = `${E.content || ""}${e.content}`), e.todo && (g.todo = `${E.todo || ""}${e.todo}`), e.progress && (g.progress = `${E.progress || ""}${e.progress}`), i.messages[S] = g, !((T = g.content) != null && T.length) && e.isComplete && i.messages.splice(S, 1), i;
        }
      }
    );
  },
  removeMessage: ({
    client: t,
    id: e,
    messageId: r
  }) => {
    $.apiRequest.update(
      {
        client: t,
        key: U(e),
        dataOrFn: (s) => {
          var E;
          if (!(s != null && s.messages)) return s;
          const i = s.messages.length, S = (E = s.messages) == null ? void 0 : E.filter(
            (g) => g.id !== r
          );
          return {
            ...s,
            messages: S,
            total: Math.max(
              (s.total || 0) - (i !== S.length ? 1 : 0),
              0
            )
          };
        }
      }
    );
  },
  removeLastMessage: ({ client: t, id: e }) => {
    $.apiRequest.update(
      {
        client: t,
        key: U(e),
        dataOrFn: (r) => r != null && r.messages ? {
          ...r,
          messages: r.messages.slice(0, -1),
          total: Math.max((r.total || 0) - 1, 0)
        } : r
      }
    );
  },
  removeThread: ({
    client: t,
    id: e,
    userId: r
  }) => {
    $.apiRequest.update({
      client: t,
      key: Ie(r),
      dataOrFn: (s) => {
        var E;
        if (!(s != null && s.threads)) return s;
        const i = s.threads.length, S = (E = s.threads) == null ? void 0 : E.filter((g) => g.id !== e);
        return {
          ...s,
          threads: S,
          total: Math.max(
            (s.total || 0) - (i !== S.length ? 1 : 0),
            0
          )
        };
      }
    });
  }
}, Ot = Re, Me = {
  add: ({ client: t, data: e, userId: r }) => {
    e && $.request.addToPages({
      client: t,
      key: z([Z], r),
      updateFn: (s) => ({
        ...s,
        count: ((s == null ? void 0 : s.count) || 0) + 1,
        sessions: [e, ...(s == null ? void 0 : s.sessions) || []]
      })
    });
  },
  update: ({ client: t, data: e, userId: r }) => {
    e && $.request.updateInPages({
      client: t,
      key: z([Z], r),
      stopOnChange: !0,
      updateFn: (s) => {
        if (!(s != null && s.sessions)) return s;
        const i = s.sessions.findIndex(
          (E) => E.sessionID === e.sessionID
        );
        if (i === -1) return s;
        const S = {
          ...s,
          sessions: [...s.sessions]
        };
        return S.sessions[i] = e, S;
      }
    });
  },
  remove: ({
    client: t,
    userId: e,
    id: r
  }) => {
    let s;
    return $.request.updateInPages({
      client: t,
      key: z([Z], e),
      stopOnChange: !0,
      updateFn: (i) => {
        if (!(i != null && i.sessions)) return i;
        const S = i.sessions.findIndex((g) => g.sessionID === r);
        if (S === -1) return i;
        const E = { ...i, sessions: [...i.sessions] };
        return E.count = Math.max(0, (E.count || 1) - 1), s = E.sessions.splice(S, 1)[0], E;
      }
    }), s;
  }
}, $e = {
  add: ({ client: t, data: e = [] }) => {
    if (!e.length) return;
    const { sessionID: r, sessionRecurrenceID: s } = e[0];
    $.request.update({
      client: t,
      key: [
        ie,
        r,
        s,
        V.GRANTED
      ],
      dataOrFn: (i = {}) => {
        var R, b;
        const S = { ...i }, E = (v, N) => (N.type === "domain" ? v[0].push(N) : v[1].push(N), v), [g, T] = ((b = (R = S == null ? void 0 : S.data) == null ? void 0 : R.viewerAccessRules) == null ? void 0 : b.reduce(E, [[], []])) || [], [D, F] = e.reduce(E, [[], []]);
        return {
          data: {
            viewerAccessRules: [
              ...D,
              ...g || [],
              ...F,
              ...T || []
            ]
          }
        };
      }
    });
  },
  remove: ({
    client: t,
    sessionId: e,
    sessionRecurrenceId: r,
    id: s
  }) => {
    $.request.update({
      client: t,
      key: [
        ie,
        e,
        r,
        V.GRANTED
      ],
      dataOrFn: (i) => {
        var E;
        const S = ((E = i == null ? void 0 : i.data.viewerAccessRules) == null ? void 0 : E.filter((g) => g.id !== s)) || [];
        return {
          ...i,
          data: {
            ...i == null ? void 0 : i.data,
            viewerAccessRules: S
          }
        };
      }
    });
  },
  // requests
  addRequest: ({
    client: t,
    sessionId: e,
    sessionRecurrenceId: r,
    data: s
  }) => {
    const i = [
      ee,
      e,
      r,
      V.IN_REVIEW
    ], E = { ...t.getQueryData(i) };
    if (!E.pages || !E.pages.length || !E.pages[0].data) {
      t.invalidateQueries(i);
      return;
    }
    E.pages[0].data.accessRules = [
      ...s,
      ...E.pages[0].data.accessRules || []
    ], t.setQueryData(i, E);
  },
  removeRequest: ({
    client: t,
    sessionId: e,
    sessionRecurrenceId: r,
    id: s
  }) => {
  }
}, yt = {
  session: Me,
  viewerAccess: $e
}, De = (t) => {
  var e;
  return (e = t == null ? void 0 : t.data) == null ? void 0 : e.data;
}, It = (t) => De(t.data), Tt = (t, e = 0) => {
  var r, s, i;
  return (i = (s = (r = t.data) == null ? void 0 : r.pages[e]) == null ? void 0 : s.data) == null ? void 0 : i.data;
}, Rt = {
  refetchOnMount: !1,
  refetchOnReconnect: !1,
  refetchOnWindowFocus: !1
}, te = (t) => (e) => ({
  cacheTime: e,
  staleTime: e,
  retry: 2,
  refetchOnMount: !0,
  refetchOnReconnect: t,
  refetchOnWindowFocus: t
}), C = {
  "7d": 6048e5,
  "3d": 2592e5,
  "1d": 864e5,
  "6h": 216e5,
  "1h": 36e5,
  "30m": 18e5,
  "15m": 9e5,
  "10m": 6e5,
  "5m": 3e5,
  "3m": 18e4,
  "1m": 6e4,
  "30s": 3e4,
  "15s": 15e3,
  "5s": 5e3,
  "3s": 3e3,
  0: 0
}, Mt = [""], $t = () => {
}, y = te(!0), Dt = {
  "7d": y(C["7d"]),
  "3d": y(C["3d"]),
  "1d": y(C["1d"]),
  "6h": y(C["6h"]),
  "1h": y(C["1h"]),
  "30m": y(C["30m"]),
  "15m": y(C["15m"]),
  "10m": y(C["10m"]),
  "5m": y(C["5m"]),
  "3m": y(C["3m"]),
  "1m": y(C["1m"]),
  "30s": y(C["30s"]),
  "15s": y(C["15s"]),
  "3s": y(C["3s"]),
  0: y(0)
}, I = te(!1), vt = {
  "7d": I(C["7d"]),
  "3d": I(C["3d"]),
  "1d": I(C["1d"]),
  "6h": I(C["6h"]),
  "1h": I(C["1h"]),
  "30m": I(C["30m"]),
  "15m": I(C["15m"]),
  "10m": I(C["10m"]),
  "5m": I(C["5m"]),
  "3m": I(C["3m"]),
  "1m": I(C["1m"]),
  "30s": I(C["30s"]),
  "15s": I(C["15s"]),
  "3s": I(C["3s"]),
  0: I(0)
}, Yt = (t) => ({
  ...te(!1)(t),
  refetchOnReconnect: !0
});
export {
  Ye as CACHE_KEY_CALENDAR_EVENT,
  Ne as CACHE_KEY_CMS_PAGE,
  He as CACHE_KEY_CMS_PAGES,
  Ke as CACHE_KEY_CURRENT_USER,
  he as CACHE_KEY_DASHBOARD_PAST_SESSIONS,
  Z as CACHE_KEY_DASHBOARD_SESSIONS,
  Pe as CACHE_KEY_FEATURED_SESSIONS,
  at as CACHE_KEY_HUBSPOT_GET_BINDING_BY_ID,
  Et as CACHE_KEY_HUBSPOT_GET_COMPANY_BY_ID,
  dt as CACHE_KEY_HUBSPOT_GET_CONTACTS,
  St as CACHE_KEY_HUBSPOT_GET_CONTACT_BY_ID,
  _t as CACHE_KEY_HUBSPOT_GET_DEAL_BY_ID,
  ft as CACHE_KEY_HUBSPOT_LIST_BINDABLE_RECORDS_AS_OPTIONS,
  ut as CACHE_KEY_HUBSPOT_PROVIDER,
  Fe as CACHE_KEY_INTEGRATIONS_CONNECTIONS,
  Ge as CACHE_KEY_INTEGRATIONS_DIRECT_URL,
  be as CACHE_KEY_INTEGRATIONS_GET_ACTION,
  ze as CACHE_KEY_INTEGRATIONS_GET_BY_OWNER_SESSION_RECURRENCE,
  we as CACHE_KEY_INTEGRATIONS_PROVIDERS,
  me as CACHE_KEY_MEETING_MEMORY_MESSAGES,
  Be as CACHE_KEY_MEETING_MEMORY_SUGGESTIONS,
  ge as CACHE_KEY_MEETING_MEMORY_THREAD,
  pe as CACHE_KEY_MEETING_MEMORY_THREADS,
  Le as CACHE_KEY_PAYMENT_DETAILS,
  Ue as CACHE_KEY_RECORDING,
  ke as CACHE_KEY_RECORDING_STATUS,
  st as CACHE_KEY_SALESFORCE_GET_ACCOUNT_BY_ID,
  tt as CACHE_KEY_SALESFORCE_GET_BINDING_BY_ID,
  it as CACHE_KEY_SALESFORCE_GET_CONTACTS,
  ot as CACHE_KEY_SALESFORCE_GET_LEAD_BY_ID,
  nt as CACHE_KEY_SALESFORCE_GET_OPPORTUNITY_BY_ID,
  rt as CACHE_KEY_SALESFORCE_LIST_BINDABLE_RECORDS_AS_OPTIONS,
  et as CACHE_KEY_SALESFORCE_PROVIDER,
  Ae as CACHE_KEY_SESSION,
  qe as CACHE_KEY_SESSION_ACCESS_RULE,
  ie as CACHE_KEY_SESSION_ACCESS_RULES,
  ce as CACHE_KEY_SESSION_AI_FEED,
  xe as CACHE_KEY_SESSION_END_SUMMARY,
  Qe as CACHE_KEY_SESSION_EPISODES,
  We as CACHE_KEY_SESSION_PENDING_RULES_STATS,
  Oe as CACHE_KEY_SESSION_PRESENCE,
  ee as CACHE_KEY_SESSION_REQUEST_ACCESS,
  Ve as CACHE_KEY_SESSION_VIEWERS,
  je as CACHE_KEY_STREAM_TOKEN,
  Je as CACHE_KEY_SUBSCRIPTION_PLANS,
  Ze as CACHE_KEY_SUBSCRIPTION_RECEIPTS,
  ye as CACHE_KEY_USER,
  Xe as CACHE_KEY_USER_MEETING_TYPES,
  Dt as activeOptions,
  ct as aiFeedCacheService,
  Yt as baseBraidOptions,
  z as buildPrivateQueryKey,
  $ as cacheService,
  Rt as disabledFetchOptions,
  Mt as emptyCacheKey,
  $t as emptyFn,
  y as getActiveOptions,
  X as getAiFeedQueryKey,
  I as getInactiveOptions,
  Tt as getInfinityRequestData,
  U as getMeetingMemoryThreadMessagesQueryKey,
  At as getMeetingMemoryThreadQueryKey,
  Ie as getMeetingMemoryThreadsQueryKey,
  It as getQueryRequestData,
  De as getRequestData,
  gt as getSessionPresenceKey,
  mt as getSessionQueryKey,
  pt as getUserQueryKey,
  vt as inactiveOptions,
  Ot as meetingMemoryCacheService,
  C as milliseconds,
  yt as sessionCacheService,
  lt as sessionPendingAccessQueryKey,
  Ct as userSessionRequestAccessQueryKey
};
