import { LogLevel } from '@waitroom/logger';
import { STORAGE_GENERAL, UserOnboarding } from '@waitroom/models';
import { createSelectors } from '@waitroom/state';
import type { LogLevel as StreamLogLevel } from 'livekit-client';
import { create } from 'zustand';
import { envs } from '../services/envs';
import { storageService } from '../services/storage';

export const checkListAttrs: KeysOf<Partial<UserOnboarding>> = [
  'didStartASession',
  'didAddSomeoneToASession',
  'didConnectCalendar',
  'didWatchDemo',
];

export type GeneralSettings = {
  deleteRequests: Dict<boolean> | undefined;
  hostsOnboarded: Dict<boolean> | undefined;
  hideBookDemo: boolean | undefined;
  hideWelcomeToDashboard: boolean | undefined;
  introShown: boolean | undefined;
  checklistShown: boolean | undefined;
  completedChecklist: typeof checkListAttrs | undefined;
  hideOnboardingPopovers: boolean | undefined;
  hideGenerateAIFeed: boolean | undefined;
  continueInMobWeb: boolean | undefined;
  debug:
    | {
        logLevel?: LogLevel;
        streamLogLevel?: StreamLogLevel;
      }
    | undefined;
};

const storageState = storageService.getParsed<GeneralSettings>(STORAGE_GENERAL);
const initialState: GeneralSettings = {
  deleteRequests: undefined,
  hostsOnboarded: undefined,
  hideBookDemo: undefined,
  hideWelcomeToDashboard: undefined,
  introShown: undefined,
  checklistShown: undefined,
  completedChecklist: undefined,
  hideOnboardingPopovers: undefined,
  hideGenerateAIFeed: undefined,
  continueInMobWeb: undefined,
  ...storageState,
  debug: {
    ...storageState?.debug,
    logLevel: (Number(
      storageState?.debug?.logLevel ?? envs.VITE_LOG_LEVEL ?? 0,
    ) ?? 1) as LogLevel,
    streamLogLevel: Number(storageState?.debug?.streamLogLevel ?? 4),
  },
};

const useGeneralSettingsStoreBase = create<GeneralSettings>()(
  () => initialState,
);

export const useGeneralSettingsStore = createSelectors(
  useGeneralSettingsStoreBase,
);

export const updateState = (
  update: (prev: GeneralSettings) => GeneralSettings,
) => {
  useGeneralSettingsStoreBase.setState((prev) => {
    const updated = update(prev);
    storageService.set(STORAGE_GENERAL, updated);
    return updated;
  });
};

export const mergeState = (settings: Partial<GeneralSettings>) => {
  useGeneralSettingsStoreBase.setState((prev) => {
    const updated = { ...prev, ...settings };
    storageService.set(STORAGE_GENERAL, updated);
    return updated;
  });
};
